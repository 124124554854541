import { useStaticQuery, graphql } from 'gatsby'
import { Tooltip } from 'react-tippy';
import React from 'react'

export default function EmojiList(data) {
    const graphQLData = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    emoji {
                        list {
                            name
                            slug
                            emoji
                            emojipediaLink
                            emojiPath
                            keywords
                            group
                            emoji_version
                            unicode_version
                            skin_tone_support
                            skin_tone_support_unicode_version
                        }
                    }
                }
            }
        }
        `)
    
    
    return (<div>
        {graphQLData.site.siteMetadata.emoji.list.map(function(iEmoji) {
            var result = ''
            var shouldBeShown = false

            if(data.searchKeyword == null || iEmoji.keywords.join('').match( new RegExp( data.searchKeyword, 'i' ) ) != null) {
                shouldBeShown = true
            }

            result = (    
                <a title={iEmoji.keywords.join(', ')} href={`#` + iEmoji.emojiPath} onClick={() => {navigator.clipboard.writeText(iEmoji.emoji); data.updateEmoji(iEmoji.emoji)}} style={{display: shouldBeShown? 'inline-block': 'none'}}>
                    {iEmoji.emoji}
                </a>
            )

            return result
        })}
    </div>)
}

/*
<Tooltip
    trigger="click"
    hideDelay="1000"
    html={(
    <div>{iEmoji.emoji} Copied</div>
    )}>

</Tooltip>
*/