import React from 'react'
import Helmet from 'react-helmet'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import Layout from '../components/layout/BaseLayout'
import Nav from '../components/layout/Nav'
import EmojiList from '../components/EmojiList'
import throttle from 'underscore'
import Twemoji from 'react-twemoji'
import EmojiMessage from '../components/EmojiMessage'
import content from '../l10n/data'


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchKeyword: null,
      searchInput: null,
      selectedEmoji: '',
      selectedMessage: ''
    }

    this.throttledSearchInputChange = throttle( this.handleSearchInputChange, 200 )
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.updateEmoji = this.updateEmoji.bind(this)
    this.getSelectedMessageHandler = this.getSelectedMessageHandler.bind(this)
  }

  handleSearchInputChange(event) {
    const query = event.target.value;
    this.setState({ searchKeyword: query })
  }

  updateEmoji(emoji) {
    this.setState({
      selectedEmoji: emoji
    })
  }

  getSelectedMessageHandler(selectedMessage) {
    this.setState({
      selectedMessage: selectedMessage
    })
  }

  render() {
    
    return (
      <div>
        <div id="wrapper" className={this.state.selectedMessage? 'shrinked': ''}>
          <div id="innerWrapper">
            <Helmet title={content.siteTitle} />

            <Header />

            {/* <Nav /> */}

            <div id="main">
              <section id="intro" className="main">
                <div className="spotlight">
                  <div className="content">
                    <div className="emoji-search">
                      <input type="text" value={this.state.searchInput} placeholder={content.searchPlaceholder} onChange={this.handleSearchInputChange} />
                      {( this.state.searchKeyword ) ? <span title={content.resetSearchTooltip} onClick={() => { this.setState({ searchKeyword: '' }) }}>❌</span>: <span>🔍</span> }
                    </div>
                    <div className="emoji-list">
                      {/* <Twemoji> */}
                        <EmojiList searchKeyword={this.state.searchKeyword} updateEmoji={this.updateEmoji} />
                      {/* </Twemoji> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
        <EmojiMessage selectedEmoji={this.state.selectedEmoji} getSelectedMessage={this.getSelectedMessageHandler}></EmojiMessage>
      </div>
    )
  }
}

export default Index