import React from 'react'
import content from '../l10n/data'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css';

class EmojiMessage extends React.Component {
    constructor(props) {
        super(props)

        // this.props.passedEmoji
        this.state = {
            textAreaInput: '',
            selectedEmoji: '',
            messageToCopy: ''
        }

        this.handleEmojiSelect = this.handleEmojiSelect.bind(this)
        this.handleMessageToCopy = this.handleMessageToCopy.bind(this)
    }

    handleEmojiSelect(event) {
        this.setState({ 
            textAreaInput: event.target.value,
            messageToCopy: this.state.messageToCopy + event.target.value
        })
    }

    handleMessageToCopy(event) {
        const messageToCopy = event.target.value
        this.setState({ 
            messageToCopy: messageToCopy
        })
        this.props.getSelectedMessage(messageToCopy)
    }

    componentDidUpdate( prevProps ) {
        if(this.props.selectedEmoji !== prevProps.selectedEmoji) {
            const messageToCopy = this.state.messageToCopy + this.props.selectedEmoji
            this.setState({
                selectedEmoji: this.props.selectedEmoji,
                messageToCopy: messageToCopy
            })

            this.props.getSelectedMessage(messageToCopy)
        }
    }

    render() {
        return (
            <div className={this.state.messageToCopy? "emoji-message shown": "emoji-message"}>
                {/* <textarea type="text" placeholder="search for mask, needle or sneezing" value={this.state.selectedEmoji} onChange={this.handleEmojiSelect}></textarea> */}
                <textarea type="text" placeholder={content.searchPlaceholder} value={this.state.messageToCopy} onChange={this.handleMessageToCopy}></textarea>
                <Tippy content={content.copied} trigger="click" delay={[0, 500]} arrow={false} animation="shift-away">
                    <button type="button" className="btn btn-copy" onClick={() => {navigator.clipboard.writeText(this.state.messageToCopy);}}>
                        <span className="normal">{content.copy}</span>
                    </button>
                </Tippy>
            </div>
        )
    }
}

export default EmojiMessage